<template>
  <div class="mySr">
    <div class="sr-left">
      <div class="cell">
        <div class="title">收入概览</div>
        <div class="money">
          <div class="m-l">
            <div>
              即将拨款
              <i class="el-icon-s-claim" style="color: #fc3514"></i>
            </div>
            <div style="margin-top: 18px; font-size: 18px">
              RM
              <span style="font-size: 24px">{{ income.up_come_grant }} </span>
            </div>
          </div>
          <div class="m-r">
            <div>
              已完成拨款
              <i class="el-icon-s-claim" style="color: #fc3514"></i>
            </div>
            <div style="margin-top: 18px; font-size: 20px">
              {{ income.complete_grant != 0 ? "RM" : "" }}
              <span style="font-size: 24px">{{
                income.complete_grant == 0 ? "- -" : income.complete_grant
              }}
              </span>
            </div>
          </div>
        </div>
        <div class="bottom-btn">
          <div>收款渠道</div>
          <div style="font-size: 14px; color: #2673dd; cursor: pointer" @click="toPush">
            我的钱包
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="cell">
        <div class="title">
          <span> 收入详情</span>
          <div>
            <el-input size="medium" clearable @clear="getIncomeDetails" v-model="searchData" placeholder="搜索订单">
              <el-button slot="append" icon="el-icon-search" @click="getIncomeDetails"></el-button>
            </el-input>
          </div>
        </div>
        <div class="list">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <div class="tab-head">
              <div style="width: 40%">订单</div>
              <div style="width: 30%">状态</div>
              <div style="width: 30%">拨款金额</div>
            </div>
            <el-tab-pane v-for="(item, index) in listData" :key="index" :label="item.title">
              <div v-if="httpShow">
                <div class="tab" v-if="item.data.length != 0">
                  <div class="tab-content">
                    <div class="tab-item" v-for="val in item.data" :key="val.id">
                      <div style="width: 40%; display: flex">
                        <div v-if="val.orderGoods">
                          <el-image style="width: 60px; height: 60px" :src="val.orderGoods[0].image" fit="fit">
                            <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                              style="width: 50px; height: 50px">
                              <path
                                d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                            </svg></el-image>
                        </div>
                        <div style="margin: 0 20px">
                          <div style="color: #000">{{ val.order_no }}</div>
                          <div style="
                              font-size: 12px;
                              color: #999;
                              margin-top: 8px;
                            ">
                            买家：{{ val.member.name }}
                          </div>
                        </div>
                      </div>
                      <div style="width: 30%">
                        {{
                          val.state == 1
                          ? "买家未支付"
                          : val.state == 2
                            ? "待出货"
                            : val.state == 3
                              ? "待发货"
                              : val.state == 4
                                ? "运输中"
                                : val.state == 5
                                  ? "买家已完成订单"
                                  : val.state == 6
                                    ? "订单已取消"
                                    : val.state == 7
                                      ? "退货/退款"
                                      : ""
                        }}
                      </div>
                      <div style="width: 30%">RM{{ val.grant_amount }}</div>
                    </div>
                  </div>
                  <div style="
                      display: flex;
                      justify-content: right;
                      margin-top: 20px;
                    ">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                      :current-page="paging.page" :page-sizes="[6, 12, 24, 32]" :page-size="paging.pageSize"
                      layout="prev, pager, next, sizes,jumper,slot " :total="paging.total">
                      <span>
                        <el-button plain>跳转</el-button>
                      </span>
                    </el-pagination>
                  </div>
                </div>
                <div v-else>
                  <el-empty description="暂无订单"></el-empty>
                </div>
              </div>
              <div v-else style="text-align: center; padding-top: 20px">
                <img :src="lodingImg" alt="" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="sr-right">
      <div class="cell">
        <div class="title">进账报表</div>
        <div>
          <el-empty style="padding: 20px" description="无进账报表" :image-size="100"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { myIncome, incomeDetails } from "@/api/shopIncome.js";
import env from "@/settings/env.js";

export default {
  data() {
    return {
      baseURL: env.fileUrlPrefix,
      searchData: "",
      activeName: 0,
      httpShow: false,
      lodingImg: require("@/assets/img/app/http.gif"),
      listData: [
        {
          title: "即将拨款",
          data: [],
        },
        {
          title: "已完成拨款",
          data: [],
        },
      ],
      income: {
        up_come_grant: 0,
        complete_grant: 0,
      },
      paging: {
        page: 1,
        pageSize: 6,
        total: 0,
      },
    };
  },
  created() {
    this.getMyIncome();
    this.getIncomeDetails();
  },
  methods: {
    getMyIncome() {
      myIncome().then((res) => {
        if (res.code == 1) {
          this.income = res.data;
        }
      });
    },
    getIncomeDetails() {
      this.httpShow = false;
      incomeDetails({
        order_id: this.searchData,
        status: this.activeName,
        page: this.paging.page,
        page_size: this.paging.pageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.httpShow = true;

          this.paging.total = res.data.total;
          this.listData[this.activeName].data = res.data.list;
        }
      });
    },
    handleClick() {
      this.getIncomeDetails();
      this.paging.page = 1;
    },
    handleSizeChange(val) {
      this.paging.pageSize = val;
      this.getIncomeDetails();
    },
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getIncomeDetails();
    },
    toPush() {
      this.$store.state.path = "bankAccount";
      this.$router.push("bankAccount");
    },
  },
};
</script>
<style lang="less" >
.mySr {
  display: flex;

  .sr-left {
    width: 70%;

    .cell {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;
      box-shadow: 0 1px 4px 0 rgb(220, 220, 220, 0.6);
      padding: 20px;

      .title {
        font-size: 18px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .money {
        display: flex;
        margin-bottom: 20px;

        .m-l {
          width: 30%;
          padding-right: 30px;
          border-right: 1px solid #e5e5e5;
          margin-right: 20px;
        }
      }

      .bottom-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fafafa;
        padding: 10px;
        font-size: 16px;
      }

      .list {
        .el-tabs__nav-wrap::after {
          height: 1px;
        }

        .el-tabs__item.is-active {
          font-weight: 600;
        }

        .el-tabs__nav {
          height: 50px;
        }

        .tab-head {
          display: flex;
          padding: 10px 20px;
          background: #f6f6f6;
          border: 1px solid #e5e5e5;
          border-radius: 4px 4px 0 0;
          font-size: 14px;
          color: #666;
        }

        .tab {
          border-right: 1px solid #e5e5e5;
          border-left: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          border-radius: 0 0 4px 4px;

          color: #666;
          padding: 0 20px;
          padding-bottom: 20px;

          .tab-content {
            // margin: 0 20px;

            .tab-item {
              border-bottom: 1px solid #e5e5e5;
              padding: 20px 0;
              font-size: 14px;
              display: flex;
            }
          }
        }
      }
    }
  }

  .sr-right {
    margin-left: 30px;
    width: 25%;

    .cell {
      padding: 30px;
      background: #fff;

      .title {
        font-size: 18px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>